import { useEffect, useState } from "react";
import ProfilePersonal from "../Components/profilePersonal";
import { useLocation, useNavigate } from "react-router-dom";
import ApplyInstantComponent from "../Components/applyInstantComponent";
import { checkInstantLoan } from "../api/loan";
import { getLocalStorage } from "../Common/cookie";
import Swal from "sweetalert2";

function ApplyNowInstant(props) {
  const { socket, logindata, setLogindata } = props;
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  // const token = query.get("token");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handlePersonalDetails(status) {
    if (status === "success") {
      navigate("/apply-now/Instant/address");
    }
  }

  useEffect(() => {
    const asyncFn = async () => {
      const c_id = getLocalStorage("rv-user")?.id;
      if (c_id && !id) {
        const response = await checkInstantLoan({
          token: getLocalStorage("rv-user")?.accessToken,
          data: {
            customer_id: c_id,
          },
        });
        if (
          response.status === 200 &&
          response?.data?.areDatesAtLeast30DaysApart
        ) {
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Your previous loan was applied within 30 days! You can't not apply new instant loan now.",
          });
          navigate("/instant-loan");
        }
      }
    };
    asyncFn();
  }, [logindata]);

  return (
    <>
      <ApplyInstantComponent>
        <h5>
          <center>Personal Details</center>
        </h5>
        <div class="contact-form pb-2">
          <div class="form-group mt-30">
            <ProfilePersonal
              msg="Proceed"
              onSubmit={handlePersonalDetails}
              logindata={logindata}
              setLogindata={setLogindata}
              instantLoan
              // loan_id = {id}
              // token = {token}
            />
          </div>
        </div>
      </ApplyInstantComponent>
    </>
  );
}
export default ApplyNowInstant;
