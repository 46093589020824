import { useEffect, useState } from "react";
import BreadCrumbs from "../Components/breadcrumbs";
import {
  getCustomerIncompleteLoans,
  getCustomerPersonalLoans,
} from "../api/customer";
import { getLocalStorage } from "../Common/cookie";
import { Link, useNavigate } from "react-router-dom";
import instantIcon from "../Assets/img/Loan/instantIcon.png";

function Loans() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "My Loans",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-user");
      if (checkCookie) {
        const response = await getCustomerPersonalLoans({
          customer_id: checkCookie.id,
          token: checkCookie.accessToken,
        });
        if (response.status === 200) {
          const filtered = response.data.filter(
            (i) => i.status_name === "Disbursed"
          );
          setData(filtered);
        }
      }
    };
    asyncFn();
  }, []);

  async function checkIncompleteLoans() {
    const checkCookie = getLocalStorage("rv-user");
    // const response = await getCustomerIncompleteLoans({
    //   customer_id: checkCookie.id,
    //   token: checkCookie.accessToken,
    // });
    // if (response.status === 200) {
    //   if (response.data?.id) {
    //     const nextLink = await checkNext(
    //       response.data.id,
    //       response.data.status
    //     );
    //     setData((prevData) => {
    //       return prevData.map((item) => {
    //         if (item.id === response.data.id) {
    //           return { ...item, nextLink: nextLink };
    //         }
    //         return item;
    //       });
    //     });
    //   }
    // }
  }

  return (
    <>
      <BreadCrumbs paths={paths} />
      <div className="container section-60">
        <div class="row gx-2 justify-content-center">
          {data.length > 0 ? (
            data.map((l) => {
              const height = l.loan_type === "InstantFromBank" ? "auto" : "70";
              const cardContent = (
                <div className="card single-card p-3 h-100">
                  <div className="d-flex justify-content-between flex-column h-100 loan-type">
                    <div>
                      <div className="d-flex">
                        <div
                          className="bg-f8f8f8 p-2 d-flex align-items-center"
                          style={{
                            borderRadius: "15px",
                            height: "100px",
                            width: "100px",
                          }}
                        >
                          <img
                            src={instantIcon}
                            alt="icon"
                            height={height}
                            width="80"
                          />
                        </div>
                        <div className="w-100 d-flex align-items-center">
                          <div>
                            <p className="mt-2 ml-3">
                              Loan for <b>₹{l.offered_amount}</b> with{" "}
                              <b>
                                {l.offered_amount > 5000
                                  ? `${l.offered_interest}%`
                                  : `₹${l.offered_interest}`}
                              </b>{" "}
                              of interest
                              <br />
                              {l.emi_amount && Number(l.emi_amount) > 0 ? (
                                <>
                                  <b>EMI:</b> ₹{l.emi_amount}
                                  <br />
                                </>
                              ) : (
                                <></>
                              )}
                              Applied on{" "}
                              {new Date(l.created_at).toLocaleDateString(
                                "en-GB"
                              )}
                              {/* <br/><button className="banner-default-btn mt-3" onClick={(e)=>navigate(`/quick-pay?id=${l.id}`)}>Pay Now</button> */}
                              {/* {l.nextLink && (
                                <div className="mt-3 ">
                                  <Link
                                    to={l.nextLink}
                                    style={{ color: "#002d5b" }}
                                  >
                                    Exciting Offers are waiting for you. Let's
                                    continue from where you left.
                                    <b> Continue</b>
                                  </Link>
                                </div>
                              )} */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );

              return (
                <div className="col-12 mt-30">
                  <div>{cardContent}</div>
                  {/* {l.status_name === "Disbursed" ? (
                    <Link to={`/loan-details/${l.id}`}>{cardContent}</Link>
                  ) : (
                    <div>{cardContent}</div>
                  )} */}
                </div>
              );
            })
          ) : (
            <>
              <h5>Your loan has not been disbursed yet.</h5>
            </>
          )}
        </div>
      </div>
    </>
  );
}
export default Loans;
// /uploads/PANCard/1715191903759-cust-PROP PAN_compressed.pdf
