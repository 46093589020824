import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ApplyInstantComponent from "../Components/applyInstantComponent";
import Modal from "react-bootstrap/Modal";
import { getLoan, plLocation, updateStatus } from "../api/loan";
import { getLocalStorage } from "../Common/cookie";
import celebration from "../Assets/img/ApplyLoan/celebration1.gif";
import celeb from "../Assets/img/ApplyLoan/Celebration.gif";
import money from "../Assets/img/LoanIcon/cong.gif";
import Swal from "sweetalert2";

function ApplyNowInstant9(props) {
  const { socket, logindata, setLogindata } = props;
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const navigate = useNavigate();
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [background, setBackground] = useState(false);
  const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Check if geolocation is available in the browser
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {}
      );
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You have to allow access to location to proceed further.",
      });
    }
  }, []);

  useEffect(() => {
    setBackground(true);
    const timer = setTimeout(() => {
      setBackground(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const asyncFn = async () => {
      const response = await getLoan({
        token: data.accessToken || getLocalStorage("rv-user")?.accessToken,
        loan_id: id,
      });
      if (response.status === 200) {
        setData(response.data);
      }
    };
    asyncFn();
  }, [logindata, id]);

  const approveOffer = async () => {
    if (location.latitude && location.longitude) {
      const response = await plLocation({
        token: getLocalStorage("rv-user")?.accessToken,
        data: {
          loan_id: id,
          lat: location.latitude.toString(),
          lon: location.longitude.toString(),
        },
      });
      if (response.status === 200) {
        navigate(`/apply-now/Instant/selfie?id=${id}`);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You have to allow access to location to proceed further.",
      });
    }
  };

  return (
    <>
      <div
        style={{ backgroundImage: background ? `url(${celebration}` : "unset" }}
      >
        <ApplyInstantComponent>
          <h5>
            <center>Congratulations!</center>
          </h5>
          <div className="d-flex align-items-center justify-content-center">
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                height: "160px",
                minWidth: "200px",
                width: "100%",
                maxWidth: "350px",
                backgroundImage: `url(${celeb}`,
                backgroundSize: "contain",
              }}
            >
              <img src={money} height="80" alt="" />
            </div>
          </div>
          <p className="mb-0">
            <center>You are eligible for a loan amount of</center>
          </p>
          <h5>
            <center>₹{data.offered_amount}</center>
          </h5>
          <div class="contact-form pb-2">
            <div class=" mt-30">
              <div className="d-flex justify-content-around">
                {/* <div>
                  <center>
                    <p className="mb-0">
                      {data.offered_amount > 5000 ? "Tenure" : "Return Date"}
                    </p>
                  </center>
                  <h5>
                    <center>
                      {data.offered_amount > 5000
                        ? data.offered_tenure + " months"
                        : new Date(data.offered_tenure).toLocaleDateString()}
                    </center>
                  </h5>
                </div> */}
                <div>
                  <center>
                    <p className="mb-0">
                      {data.offered_amount > 5000 ? "ROI (P.M.)" : "Interest"}{" "}
                    </p>
                  </center>
                  <h5>
                    <center>
                      {data.offered_amount > 5000
                        ? data.offered_interest + "%"
                        : "₹" + data.offered_interest}
                    </center>
                  </h5>
                </div>
                {data.offered_amount > 5000 && (
                  <div>
                    <center>
                      <p className="mb-0">EMI</p>
                    </center>
                    <h5>
                      <center>₹{data.emi_amount}</center>
                    </h5>
                  </div>
                )}
              </div>
              {/* <div className="card p-3">
                            <table className='mb-0'>
                                <tr>
                                    <td>Loan Amount</td>
                                    <td style={{ textAlign: "right" }}>₹{data.offered_amount}</td>
                                </tr>
                                {data.emi_amount && <tr>
                                    <td>EMI Amount</td>
                                    <td style={{ textAlign: "right" }}>₹{data.emi_amount}</td>
                                </tr>}
                                {data.processing_fee && <tr>
                                    <td>Processing Fee</td>
                                    <td style={{ textAlign: "right" }}>₹{data.processing_fee}</td>
                                </tr>}
                                <tr>
                                    <td>Application No.</td>
                                    <td style={{ textAlign: "right" }}>{data.id}</td>
                                </tr>
                                <tr>
                                    <td>Loan Tenure</td>
                                    <td style={{ textAlign: "right" }}>{data.offered_tenure} {data.offered_tenure_in}</td>
                                </tr>
                                <tr>
                                    <td>Interest Rate</td>
                                    <td style={{ textAlign: "right" }}>{data.offered_interest}%</td>
                                </tr>
                            </table>
                        </div> */}
              {/* <center>
              <h6 className="mt-3">
                View{" "}
                <u onClick={handleShow} style={{ cursor: "pointer" }}>
                  Fees and charges details
                </u>
              </h6>
            </center> */}
              {/* </div> */}
              <div className="mt-2">
                <center>
                  <button
                    type="submit"
                    onClick={approveOffer}
                    class="default-btn disabled mt-3"
                    style={{ pointerEvents: "all", cursor: "pointer" }}
                  >
                    Proceed <span></span>
                  </button>
                </center>

                {/* <button onClick={approveOffer} style={{ padding: "8px", border: "0", borderRadius: "8px", backgroundColor: "#008000", color: "#fff", marginRight: "10px" }}>Approve Offer</button>
                        <button onClick={rejectOffer} style={{ padding: "8px", border: "0", borderRadius: "8px", backgroundColor: "#FF0000", color: "#fff" }}>Don't Approve Offer</button> */}
              </div>
              {/* <h5>Reference</h5>                         */}
              {/* <ReviewLoan msg="Next" onSubmit={handleReviewLoan} loandata={id} /> */}
              {/* <button type="submit" onClick={saveData} class="default-btn disabled" style={{ pointerEvents: "all", cursor: "pointer" }}>Proceed <span></span></button> */}
            </div>
          </div>
        </ApplyInstantComponent>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <table className="instantLoan">
            <tr>
              <td>Additional / Penal Interest</td>
              <td>3% of pending amount per month</td>
            </tr>
            <tr>
              <td>Instrument Return Charges (Bounce Charges)</td>
              <td>₹500</td>
            </tr>
          </table>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default ApplyNowInstant9;
