import { useEffect, useState } from "react";
import BreadCrumbs from "../Components/breadcrumbs";
import repay from "../Assets/img/Banner/new/Payment Information.gif";
import step1 from "../Assets/img/enterNumber.png";
import step3 from "../Assets/img/getSMS.png";
import step2 from "../Assets/img/verifyDetails.png";
import { getLoan, getLoanEMI, getLoanForCust } from "../api/loan";
import { validateContact, validateText } from "../Common/validations";
import { useLocation } from "react-router-dom";
import { getLocalStorage } from "../Common/cookie";
import { getCustomer } from "../api/customer";
import { createOrder, paymentStatus } from "../api/cashfree";
import { load } from "@cashfreepayments/cashfree-js";

function QuickPay(props) {
  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Quick Pay",
    },
  ];
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const loan_id = queryParams.get("id");

  const [data, setData] = useState({});
  const [loandata, setLoanData] = useState({});
  const [emidata, setemiData] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getLoanData();
    getEMIData();
  }, [loan_id, props.logindata]);

  const getLoanData = async () => {
    const response = await getLoan({
      token: data.accessToken || getLocalStorage("rv-user")?.accessToken,
      loan_id: loan_id,
    });
    if (response.status === 200) {
      setLoanData(response.data);
    }
  };
  const getEMIData = async () => {
    const response = await getLoanEMI({
      token: data.accessToken || getLocalStorage("rv-user")?.accessToken,
      loan_id: loan_id,
    });
    if (response.status === 200) {
      const a = response.data.find((i) => i.payment_status === "Pending");
      const dueDate = new Date(a.due_date); 
      const today = new Date();
      today.setHours(0, 0, 0, 0); 
      dueDate.setHours(0, 0, 0, 0);
      if (today > dueDate) {
        a.penalty_amount = 500
      } else {
        a.penalty_amount = 0
      }
      setemiData(a);
    }
  };

  const payAmount = async () => {
      const checkCookie = getLocalStorage("rv-user");
      if (checkCookie) {
        const response = await getCustomer({
          customer_id: checkCookie.id,
          token: checkCookie.accessToken,
        });
        if (response.status === 200) {
          const amount = Number(emidata.emi_amount) + Number(emidata.penalty_amount)
          const res = await createOrder({
            data: {
              c_uid: `${response.data.id}_${response.data.phone}`,
              amount: amount,
              name: response.data.name,
              email: response.data.email,
              phone: response.data.phone
            }
          })
          const cashfree = await load({
            // mode: process.env.REACT_APP_CASHFREE_MODE
            mode: "production"
          });
          cashfree.checkout({
            paymentSessionId: res.data.payment_session_id,
            redirectTarget: "_modal",
            // returnUrl: "https://test.cashfree.com/pgappsdemos/v3success.php?myorder={order_id}"
            // returnUrl: `https://rvcapitalfinance.com/`
          });
          // navigate(`/apply-now/Instant/review?id=${id}`);
          let paymentOptions = {
            // paymentMethod: component,
            paymentSessionId: res.data.payment_session_id,
              // returnUrl: "https://example.com?order={order_id}"
          }
          cashfree.pay(paymentOptions).then(async function (result) {
            console.log(result);           
            if(result.error) {
              //there is an error
              //message at result.error.message
            }
            if(result.redirect){
              //console.log("User would be redirected");
            }
            if(result.paymentDetails) {
              console.log(result.paymentDetails);
              //only when redirect = 'if_required' for UPI payment
              //payment is successful
              //message is at result.paymentDetails.paymentMessage
            }
            const r = await paymentStatus({
              order_id: res.data.order_id
            })
            if(r.status === 200){
              console.log(r.data);
              
            }
          });
         
        }
      }
  }

  const process = [
    { title: "Enter Loan Number", image: step1 },
    { title: "Verify Details", image: step2 },
    { title: "Receive Payment Confirmation", image: step3 },
  ];
  // function onChangeData(e) {
  //   if (e.target.name === "phone" && isNaN(e.target.value)) {
  //     console.log("inside");
  //     return false;
  //   } else {
  //     setData((data) => ({
  //       ...data,
  //       [e.target.name]: e.target.value,
  //     }));
  //   }
  // }

  // function validatePhoneNo(str) {
  //   setErrors((errors) => ({
  //     ...errors,
  //     phone: validateContact(str).error,
  //   }));
  // }
  // function validateLoanNo(str) {
  //   setErrors((errors) => ({
  //     ...errors,
  //     loanno: validateText(str).error,
  //   }));
  // }
  // function validateForm() {
  //   validatePhoneNo(data.phone);
  //   validateLoanNo(data.loanno);
  //   let valid =
  //     validateContact(data.phone).valid && validateText(data.loanno).valid;
  //   return valid;
  // }

  // const getData = async (e) => {
  //   e.preventDefault();
  //   if (validateForm()) {
  //     const response = await getLoanForCust({
  //       loan_id: data.loanno,
  //       phone: data.phone,
  //     });
  //     if (response.status === 200) {
  //       setLoanData(response.data);
  //       setData({
  //         loanno: "",
  //         phone: "",
  //       });
  //     } else {
  //       setErrors({
  //         ...errors,
  //         phone: response.message,
  //       });
  //     }
  //   }
  // };

  return (
    <>
      <BreadCrumbs paths={paths} />

      {loan_id ? (
        <div className="section-60">
          <div className="container">
            <div class="section-title">VERIFY Your DETAILS</div>
            <div className="sub-title text-center pb-3">
              Experience peace of mind with our secure online payment options
              for your loan/insurance. Choose from a variety of convenient
              methods including Debit Cards (Master/Visa/Rupay/Maestro),
              Netbanking, UPI (Unified Payments Interface), and credit cards.
            </div>
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="d-flex justify-content-center align-items-center">
                  <img class="banner-img" src={repay} alt="Your Image" />
                </div>
              </div>
              <div className="col-md-6 col-12 ">
                <div className="h-100 d-flex align-items-center">
                  <div>
                    <table>
                      <tr>
                        <td className="label-login">Name</td>
                        <td>{loandata?.customer?.name}</td>
                      </tr>
                      <tr>
                        <td className="label-login">Loan Amount</td>
                        <td>₹{loandata.offered_amount}</td>
                      </tr>
                      <tr>
                        <td className="label-login">Loan Interest</td>
                        <td>
                          {" "}
                          {loandata.offered_amount > 5000
                            ? loandata.offered_interest + "%"
                            : "₹" + loandata.offered_interest}
                        </td>
                      </tr>
                      <tr>
                        <td className="label-login">Due Date</td>
                        <td>
                          {new Date(emidata.due_date).toLocaleDateString()}
                        </td>
                      </tr>
                      <tr>
                        <td className="label-login">Total Payable Amount</td>
                        <td>{loandata.total_payable_amount}</td>
                      </tr>
                      {emidata?.penalty_amount ?
                      <tr>
                        <td className="label-login">EMI Amount</td>
                        <td>{loandata.emi_amount}</td>
                      </tr> : <></>
                      }
                    </table>
                    <button
                      class="default-btn disabled"
                      style={{ pointerEvents: "all", cursor: "pointer" }}
                      onClick={payAmount}
                    >
                      Proceed To Pay<span></span>
                    </button>
                    {/* <div
                      className="label-login"
                      style={{ cursor: "pointer" }}
                      onClick={() => setLoanData({})}
                    >
                      Back
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="section-60">
          <div className="container">
            <div class="section-title">Online Payment Facility</div>
            <div className="sub-title text-center pb-3">
              Experience peace of mind with our secure online payment options
              for your loan/insurance. Choose from a variety of convenient
              methods including Debit Cards (Master/Visa/Rupay/Maestro),
              Netbanking, UPI (Unified Payments Interface), and credit cards.
            </div>
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="d-flex justify-content-center align-items-center">
                  <img class="banner-img" src={repay} alt="Your Image" />
                </div>
              </div>
              <div className="col-md-6 col-12 ">
                <div className="h-100 d-flex align-items-center">
                  <div class="contact-form " style={{ minWidth: "100%" }}>
                    <form novalidate="true">
                      <div class="form-group">
                        <label className="label-login">Loan Number: </label>
                        <input
                          type="text"
                          name="loanno"
                          class="form-control w-100"
                          value={data.loanno}
                          // onChange={(e) => onChangeData(e)}
                        />
                        {errors.loanno && (
                          <div class="help-block with-errors">
                            {errors.loanno}
                          </div>
                        )}
                      </div>
                      <div class="form-group">
                        <label className="label-login">Phone Number: </label>
                        <input
                          type="text"
                          maxLength={10}
                          name="phone"
                          class="form-control w-100"
                          value={data.phone}
                          // onChange={(e) => onChangeData(e)}
                        />
                        {errors.phone && (
                          <div class="help-block with-errors">
                            {errors.phone}
                          </div>
                        )}
                      </div>
                      <button
                        // onClick={getData}
                        class="default-btn disabled"
                        style={{ pointerEvents: "all", cursor: "pointer" }}
                      >
                        Proceed <span></span>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="section-60 bg-f8f8f8">
        <div className="container">
          <div class="section-title">Pay your EMI in 3 simple steps</div>
          <div class="about-inner-area mt-0">
            <div class="row justify-content-center">
              {process.map((s) => {
                return (
                  <div class="col-lg-4 col-md-6 col-sm-6 mb-30">
                    <div class="single-about-box h-100 pb-0">
                      <div class="icon">
                        <img src={s.image} alt="step" />
                      </div>
                      <h3 className="mb-0">{s.title}</h3>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default QuickPay;
