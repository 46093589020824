import { useState, useEffect } from "react";
import BreadCrumbs from "../Components/breadcrumbs";

import { toast } from "react-toastify";
import { addContact } from "../api/contact";
import {
  validateContact,
  validateEmail,
  validateName,
  validateText,
} from "../Common/validations";

function Contact(props) {
  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Contact",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  function onChangeData(e) {
    if (e.target.name === "phone" && isNaN(e.target.value)) {
      return false;
    } else {
      setData((data) => ({
        ...data,
        [e.target.name]: e.target.value,
      }));
    }
  }

  function validateEmailAddr(str) {
    setErrors((errors) => ({
      ...errors,
      email: validateEmail(str).error,
    }));
  }
  function validatePhoneNo(str) {
    setErrors((errors) => ({
      ...errors,
      phone: validateContact(str).error,
    }));
  }
  function validateCName(str) {
    setErrors((errors) => ({
      ...errors,
      name: validateName(str).error,
    }));
  }
  function validateMsg(str) {
    setErrors((errors) => ({
      ...errors,
      message: validateText(str).error,
    }));
  }

  function validateForm() {
    validateEmailAddr(data.email);
    validatePhoneNo(data.phone);
    validateCName(data.name);
    validateMsg(data.message);
    let valid =
      validateEmail(data.email).valid &&
      validateContact(data.phone).valid &&
      validateText(data.message).valid &&
      validateName(data.name).valid;
    return valid;
  }

  const addcontact = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const response = await addContact({
        data: {
          name: data.name,
          email: data.email,
          phone: data.phone,
          message: data.message,
        },
      });
      if (response.status === 200) {
        toast.success("Your query added successfully.");
        setData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
      }
    }
  };

  return (
    <>
      <BreadCrumbs paths={paths} />
      <div className="section-60 bg-f8f8f8">
        <div className="container">
          <div class="section-title">DROP US MESSAGE FOR ANY QUERY</div>
          <div className="sub-title text-center pb-3">
            Reach out to us for expert assistance and support. We're here to
            address your queries and provide top-notch service.
          </div>
          <div class="row pt-3">
            <div class="col-lg-6 col-md-12  mb-30">
              <div class="contact-form">
                <form id="contactForm" novalidate="true">
                  <div class="row">
                    <div class="col-lg-12 col-md-6">
                      <div class="form-group">
                        <input
                          type="text"
                          name="name"
                          class="form-control"
                          value={data.name}
                          onBlur={() => validateCName(data.name)}
                          onChange={(e) => onChangeData(e)}
                          placeholder="Name"
                        />
                        {errors.name && (
                          <div class="help-block with-errors">
                            {errors.name}
                          </div>
                        )}
                      </div>
                    </div>

                    <div class="col-lg-12 col-md-6">
                      <div class="form-group">
                        <input
                          type="email"
                          name="email"
                          class="form-control"
                          value={data.email}
                          onBlur={() => validateEmailAddr(data.email)}
                          onChange={(e) => onChangeData(e)}
                          placeholder="Email"
                        />
                        {errors.email && (
                          <div class="help-block with-errors">
                            {errors.email}
                          </div>
                        )}
                      </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                      <div class="form-group">
                        <input
                          type="text"
                          name="phone"
                          class="form-control"
                          value={data.phone}
                          onBlur={() => validatePhoneNo(data.phone)}
                          onChange={(e) => onChangeData(e)}
                          placeholder="Phone"
                        />
                        {errors.phone && (
                          <div class="help-block with-errors">
                            {errors.phone}
                          </div>
                        )}
                      </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                      <div class="form-group">
                        <textarea
                          name="message"
                          class="form-control"
                          cols="30"
                          rows="6"
                          value={data.message}
                          onBlur={() => validateMsg(data.message)}
                          onChange={(e) => onChangeData(e)}
                          placeholder="Your Message"
                          data-gramm="false"
                          wt-ignore-input="true"
                        ></textarea>
                        {errors.message && (
                          <div class="help-block with-errors">
                            {errors.message}
                          </div>
                        )}
                      </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                      <button
                        onClick={addcontact}
                        class="default-btn disabled"
                        style={{ pointerEvents: "all", cursor: "pointer" }}
                      >
                        Send Message <span></span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div class="col-lg-6 col-md-12">
              <div id="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3762.479314839285!2d72.85124587521437!3d19.434890681845022!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTnCsDI2JzA1LjYiTiA3MsKwNTEnMTMuOCJF!5e0!3m2!1sen!2sin!4v1715880628956!5m2!1sen!2sin"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Contact;
