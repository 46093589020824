import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ApplyInstantComponent from "../Components/applyInstantComponent";
import ProfileProfessional from "../Components/profileProfessional";

function ApplyNowInstant2(props) {
  const { socket, logindata, setLogindata } = props;
  const navigate = useNavigate();
  const location = useLocation(); 
  const queryParams = new URLSearchParams(location.search);
  const type_name = queryParams.get("type");
  const id = queryParams.get("id");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handleProfessionalDetails(status) {
    if (status === "success") {
      navigate("/apply-now/Instant/request");
    }
  }

  return (
    <>
      <ApplyInstantComponent>
        <h5>
          <center>Professional Information</center>
        </h5>
        <div class="contact-form pb-2">
          <div class="form-group mt-30">
            <ProfileProfessional
              msg="Proceed"
              onSubmit={handleProfessionalDetails}
              logindata={logindata}
              setLogindata={setLogindata}
              type_name={type_name}
              loanId ={id}
            />
          </div>
        </div>
      </ApplyInstantComponent>
    </>
  );
}
export default ApplyNowInstant2;
