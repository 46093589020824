import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ApplyInstantComponent from "../Components/applyInstantComponent";
import ProfileProfessional from "../Components/profileProfessional";
import RequestLoan from "../Components/requestLoan";
import ProfileIdentity from "../Components/profileIdentity";
import { createDigiLockerLink } from "../api/cashfree";
import { getLocalStorage } from "../Common/cookie";

function ApplyNowInstant4(props) {
  const { socket, logindata, setLogindata } = props;
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState("");
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function isValidURL(url) {
    const pattern =
      /^https:\/\/rvcapitalfinance\.com\/\?verification_id=\d+_\d+$/;
    return pattern.test(url);
  }

  async function saveData() {
    if (isChecked) {
      setError("");
      const checkCookie = getLocalStorage("rv-user");
      const response = await createDigiLockerLink({
        data: {
          verification_id: `${checkCookie.id}_${new Date().getTime()}`,
          loan_id: id,
          customer_id: checkCookie.id
        },
        token: checkCookie.accessToken,
      });
      if (response.data.url) {        
        window.location.href = response.data.url   
        // navigate(`/apply-now/Instant/bank?id=${id}`);
      }
    } else {
      setError("Accept the terms and Policies.");
    }
  }

  return (
    <>
      <ApplyInstantComponent>
        <h5>
          <center>Complete KYC</center>
        </h5>
        <div class="contact-form pb-2">
          <div class="form-group mt-30">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
            />{" "}
            <b>
              {" "}
              By continuing, I agree to the{" "}
              <a href="https://rvcapitalfinance.com/terms" target="_blank">
                <u>Terms and Conditions</u>
              </a>{" "}
              ,{" "}
              <a href="https://rvcapitalfinance.com/policy" target="_blank">
                <u>Privacy Policy</u>
              </a>{" "}
              of RV Capital Finance.
            </b>
            {error && <div class="help-block with-errors">{error}</div>}
          </div>
          <center>
            <button
              type="submit"
              onClick={saveData}
              class="default-btn disabled my-75"
              style={{ pointerEvents: "all", cursor: "pointer" }}
            >
              Proceed <span></span>
            </button>
          </center>
        </div>
      </ApplyInstantComponent>
    </>
  );
}
export default ApplyNowInstant4;
