import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ApplyInstantComponent from "../Components/applyInstantComponent";
import ReviewLoan from "../Components/reviewLoan";
import { validateContact, validateName } from "../Common/validations";
import { getLoan, updateRef, updateStatus } from "../api/loan";
import { getLocalStorage } from "../Common/cookie";
import celebration from "../Assets/img/ApplyLoan/celebration1.gif";

import pdf from "../Assets/img/pdf.png";
import Modal from "react-bootstrap/Modal";
import logo from "../Assets/img/logoLight.png";
import approve from "../Assets/img/LoanIcon/approved.png";
function ApplyNowInstant11(props) {
  const { socket, logindata, setLogindata } = props;
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [background, setBackground] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setBackground(true);
    const timer = setTimeout(() => {
      setBackground(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const downloadPdf = async (path, name) => {
    try {
      const response = await fetch(path, {
        headers: {
          "Content-Type": "application/pdf",
        },
      });
      if (!response.ok) {
        console.error("Network response was not ok");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name); // Specify the file name
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading the PDF", error);
    }
  };

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-user");
      if (checkCookie) {
        const response = await getLoan({
          token: data.accessToken || getLocalStorage("rv-user")?.accessToken,
          loan_id: id,
        });
        if (response.status === 200) {
          setData(response.data);
          const timer = setTimeout(async () => {
            await downloadPdf(
              `${process.env.REACT_APP_API}/uploads/Loan/Agreement/${response.data.agreement}`,
              response.data.agreement.split("/").pop()
            );
          }, 1000);

          return () => clearTimeout(timer);
        }
      }
    };
    asyncFn();
  }, [id, logindata]);

  async function goHome() {
    navigate("/");
  }

  return (
    <>
      <div
        style={{ backgroundImage: background ? `url(${celebration}` : "unset" }}
      >
        <ApplyInstantComponent>
          <center>
            <h3>
              <center>Congrats!!!</center>
            </h3>
          </center>
          <div class="contact-form">
            <div class="form-group mt-3">
              <center>
                <img src={approve} width="100" alt="" />
              </center>
             
              <p className="mt-30 mx-3">
                Your Personal Loan of <b>Rs. {data.offered_amount}</b> is ready
                for disbursal. Amount will be credited in your bank account very
                soon. You can download statement from{" "}
                <b style={{cursor:"pointer"}}
                  onClick={async() => {await downloadPdf(
                    `${process.env.REACT_APP_API}/uploads/Loan/Agreement/${data.agreement}`,
                    data?.agreement?.split("/").pop()
                  )}}
                >
                  here.
                </b>
              </p>
              <div className="card p-3 mt-30 mb-30" style={{backgroundColor: "#e5eaee"}}>
                <table className="mb-0">
                <tr>
                    <td>Application No.</td>
                    <td style={{ textAlign: "right" }}>{data.id}</td>
                  </tr>
                  <tr>
                    <td>Sanctioned Amount</td>
                    <td style={{ textAlign: "right" }}>
                      ₹{data.offered_amount}
                    </td>
                  </tr>
                  {data?.emi_amount > 0 && (
                    <tr>
                      <td>EMI Amount</td>
                      <td style={{ textAlign: "right" }}>₹{data.emi_amount}</td>
                    </tr>
                  )}
                  {data.processing_fee && (
                    <tr>
                      <td>Processing Fee</td>
                      <td style={{ textAlign: "right" }}>
                        ₹{data.processing_fee}
                      </td>
                    </tr>
                  )}
                  
                  <tr>
                    <td>Loan Tenure</td>
                    <td style={{ textAlign: "right" }}>
                      {data.offered_amount > 5000
                        ? `${data.offered_tenure} months`
                        : new Date(data.offered_tenure).toLocaleDateString()}
                    </td>
                  </tr>
                  <tr>
                    <td>Interest Rate</td>
                    <td style={{ textAlign: "right" }}>
                      {data.offered_amount > 5000
                        ? `${data.offered_interest}%`
                        : `₹${data.offered_interest}`}
                    </td>
                  </tr>
                </table>
              </div>
                <center>
                  <button onClick={goHome} className="default-btn btn-sm mt-3">
                    Go To Home
                  </button>
                </center>
            </div>
          </div>
        </ApplyInstantComponent>
      </div>
    </>
  );
}
export default ApplyNowInstant11;
