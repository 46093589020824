import axios from "axios";

export const getLoan = (obj) => {
  return axios
    .get("/personal-loan/" + obj.loan_id, {
      headers: { Authorization: `Bearer ${obj.token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};
export const getLoanEMI = (obj) => {
  return axios
    .get("/loan-emi/" + obj.loan_id, {
      headers: { Authorization: `Bearer ${obj.token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};

export const getLoanForCust = (obj) => {
  return axios
    .get("/loan/" + obj.loan_id + "/" + obj.phone)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};

export const applyLoan = (obj) => {
  return axios
    .post("/loan/apply", obj.data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};

export const applyBankPersonalLoan = (obj) => {
  return axios
    .post("/bank-personal-loan/apply", obj.data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};
export const checkInstantLoan = (obj) => {
  return axios
    .post("/personal-loan/check", obj.data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};
export const applyInstantLoan = (obj) => {
  return axios
    .post("/personal-loan/apply", obj.data, {
      headers: { Authorization: `Bearer ${obj.token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};

export const updateStatus = (obj) => {
  return axios
    .post("/loan/status", obj.data, {
      headers: { Authorization: `Bearer ${obj.token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};

export const updatePLStatus = (obj) => {
  return axios
    .patch("/personal-loan/status", obj.data, {
      headers: { Authorization: `Bearer ${obj.token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};
export const plSelfie = (obj) => {
  return axios
    .post("/personal-loan/selfie", obj.data, {
      headers: { Authorization: `Bearer ${obj.token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};
export const plLocation = (obj) => {
  return axios
    .post("/personal-loan/location", obj.data, {
      headers: { Authorization: `Bearer ${obj.token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};

export const updateRef = (obj) => {
  return axios
    .post("/loan/reference", obj.data, {
      headers: { Authorization: `Bearer ${obj.token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};
