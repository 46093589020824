import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ApplyInstantComponent from "../Components/applyInstantComponent";
import ReviewLoan from "../Components/reviewLoan";
import pdf from "../Assets/img/pdf.png";
import { getLocalStorage } from "../Common/cookie";
import { getLoan, plSelfie } from "../api/loan";
import { validateText } from "../Common/validations";
import { uploadBankStatement } from "../api/customerAuth";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";

function ApplyNowInstantSelfie(props) {
  const { socket, logindata, setLogindata } = props;
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const navigate = useNavigate();
  const [dataUri, setDataUri] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function saveData(e) {
    e.preventDefault();
    if (dataUri) {
      const response = await plSelfie({
        token: getLocalStorage("rv-user")?.accessToken,
        data: {
          loan_id: id,
          photo: dataUri,
        },
      });
      if (response.status === 200) {
        navigate(`/apply-now/Instant/kyc?id=${id}`);
      }
    }
  }

  const ImagePreview = ({ dataUri }) => {
    return (
      <div className={"demo-image-preview "}>
        <img src={dataUri} />
      </div>
    );
  };

  return (
    <>
      <ApplyInstantComponent>
        <h5>
          <center>Upload Selfie</center>
        </h5>
        <div class="contact-form pb-2">
          {dataUri ? (
            <>
              <ImagePreview dataUri={dataUri} />
              <center className="mt-30">
                <button
                  type="submit"
                  onClick={() => setDataUri("")}
                  class="default-btn disabled"
                  style={{ pointerEvents: "all", cursor: "pointer" }}
                >
                  Retake <span></span>
                </button>
                <button
                  type="submit"
                  onClick={saveData}
                  class="default-btn disabled"
                  style={{
                    pointerEvents: "all",
                    cursor: "pointer",
                    marginLeft: "30px",
                  }}
                >
                  Upload <span></span>
                </button>
              </center>
            </>
          ) : (
            <Camera
              onTakePhoto={(dataUri) => {
                setDataUri(dataUri);
              }}
            />
          )}
        </div>
      </ApplyInstantComponent>
    </>
  );
}

export default ApplyNowInstantSelfie;
