import { useEffect, useState } from "react";
import htmlToPdf from "html-to-pdf-js";
import calcImg from "../Assets/img/Banner/new/Calculator.gif";
import { getEmiCalc } from "../api/emiCalc";
import "./emiCalc.css";

function Calc(props) {
  const { minmax } = props;

  const [data, setData] = useState({
    amount: null,
    interest: null,
    tenure: null,
  });

  const [emi, setEmi] = useState(null);
  const [totalInterestAmount, setTotalInterestAmount] = useState(null);
  const [total, setTotal] = useState(null);
  const [schedule, setSchedule] = useState(null);
  const [scheduleDisplay, setScheduleDisplay] = useState("none");

  function onChangeData(e) {
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  }

  const validateEMiCalc = (e, minmaxvalue) => {
    let value = e.target.value;
    const min = minmaxvalue.min_value;
    const max = minmaxvalue.max_value;
    if (Number(value) < Number(min)) {
      setData((data) => ({
        ...data,
        [e.target.name]: min,
      }));
    } else if (Number(value) > Number(max)) {
      setData((data) => ({
        ...data,
        [e.target.name]: max,
      }));
    }
  };

  const calculateEMI = () => {
    const monthlyInterestRate = data.interest / 12 / 100;
    const totalPayments = data.tenure * 12;
    const EMI =
      (data.amount *
        monthlyInterestRate *
        Math.pow(1 + monthlyInterestRate, totalPayments)) /
      (Math.pow(1 + monthlyInterestRate, totalPayments) - 1);
    setEmi(EMI);

    let scheduleHTML = "<table border='1'>";
    scheduleHTML +=
      '<tr><th style="text-align:center;">Month</th><th style="text-align:center;">Payment</th><th style="text-align:center;">Principal</th><th style="text-align:center;">Interest</th><th style="text-align:center;">Balance</th></tr>';

    let balance = data.amount;
    let totalInterest = 0;
    for (let month = 1; month <= data.tenure * 12; month++) {
      const interest = balance * monthlyInterestRate;
      totalInterest += interest;
      const principal = EMI - interest;
      balance -= principal;
      scheduleHTML += `<tr><td style="text-align:right;">${month}</td><td style="text-align:right;">${EMI.toFixed(
        0
      )}</td><td style="text-align:right;">${principal.toFixed(
        0
      )}</td><td style="text-align:right;">${interest.toFixed(
        0
      )}</td><td style="text-align:right;">${balance.toFixed(0)}</td></tr>`;
    }
    scheduleHTML += "</table>";
    setSchedule(scheduleHTML);
    setTotalInterestAmount(totalInterest);
    const t = Number(totalInterest) + Number(data.amount);
    setTotal(t);
  };

  const PDFDownload = () => {
    const element = document.createElement("div");
    let html =
      `<div style="padding:50px"><center style="padding-bottom:30px"><b style="font-size:28px, padding-bottom: 30px">RV Capital</b><br/><b>Loan Schedule for ${data.amount} amount on ${data.interest}% interest rate for ${data.tenure} year</b></center>` +
      schedule +
      "</div>";
    element.innerHTML = html;

    htmlToPdf(element, (pdf) => {
      pdf.save("download.pdf");
    });
  };

  return (
    <>
      <div className="row">
        <div class="col-md-6 col-12">
          <div class="contact-form pb-2">
            <div class="form-group">
              <label className="label-login">Amount</label>
              <input
                type="number"
                name="amount"
                class="form-control"
                value={data.amount}
                onChange={(e) => {
                  onChangeData(e);
                }}
                onBlur={(e) => {
                  validateEMiCalc(e, minmax[0]);
                }}
              />
              <div className="d-flex justify-content-between">
                <div>{minmax[0].min_value} Rs.</div>
                <div>{minmax[0].max_value} Rs.</div>
              </div>
              <div class="help-block with-errors"></div>
            </div>
            <div class="form-group">
              <label className="label-login">Interest</label>
              <input
                type="number"
                name="interest"
                class="form-control"
                value={data.interest}
                onChange={(e) => {
                  onChangeData(e);
                }}
                onBlur={(e) => {
                  validateEMiCalc(e, minmax[2]);
                }}
              />
              <div className="d-flex justify-content-between">
                <div>{minmax[2].min_value}%</div>
                <div>{minmax[2].max_value}%</div>
              </div>
              <div class="help-block with-errors"></div>
            </div>
            <div class="form-group">
              <label className="label-login">Tenure</label>
              <input
                type="number"
                name="tenure"
                class="form-control"
                value={data.tenure}
                onChange={(e) => {
                  onChangeData(e);
                }}
                onBlur={(e) => {
                  validateEMiCalc(e, minmax[1]);
                }}
              />
              <div className="d-flex justify-content-between">
                <div>{minmax[1].min_value} Yr</div>
                <div>{minmax[1].max_value} Yr</div>
              </div>
              <div class="help-block with-errors"></div>
            </div>

            <button
              class="default-btn disabled"
              disabled={!(data.amount && data.tenure && data.interest)}
              onClick={calculateEMI}
              style={{ pointerEvents: "all", cursor: "pointer" }}
            >
              Calculate <span></span>
            </button>
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="loan-calc mt-0 d-flex align-items-center justify-content-center">
            {emi ? (
              <div>
                Monthly EMI: <b>{emi && emi.toFixed(0)}</b>
                <hr />
                Total Interest:{" "}
                <b>{totalInterestAmount && totalInterestAmount.toFixed(0)}</b>
                <hr />
                Payable Amount: <b>{total && total.toFixed(0)}</b>
                <div className="d-flex justify-content-center mt-3">
                  {scheduleDisplay === "none" ? (
                    <button
                      class="default-btn p-3"
                      onClick={() => setScheduleDisplay("block")}
                    >
                      Repayment Schedule
                    </button>
                  ) : (
                    <button
                      class="default-btn p-3"
                      onClick={() => setScheduleDisplay("none")}
                    >
                      Hide Schedule
                    </button>
                  )}
                  <button class="default-btn ml-3 p-3" onClick={PDFDownload}>
                    Download Schedule
                  </button>
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <img src={calcImg} alt="" style={{ maxHeight: "250px" }} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row mt-3" style={{ display: scheduleDisplay }}>
        <div
          className="col-12"
          id="demo"
          dangerouslySetInnerHTML={{ __html: schedule }}
        ></div>
      </div>
    </>
  );
}
function EMICalc(props) {
  const [minmax, setMinmax] = useState([
    {
      name: "amount",
      min_value: null,
      max_value: null,
    },
    {
      name: "tenure",
      min_value: null,
      max_value: null,
    },
    {
      name: "interest",
      min_value: null,
      max_value: null,
    },
  ]);

  useEffect(() => {
    const asyncFn = async () => {
      const response = await getEmiCalc({});
      if (response.status === 200) {
        setMinmax(response.data);
      }
    };
    asyncFn();
  }, []);

  return (
    <>
      {props.noExtra ? (
        <Calc minmax={minmax} />
      ) : (
        <div className="section-60 bg-f8f8f8">
          <div className="container">
            <div class="section-title">EMI calculator</div>
            <Calc minmax={minmax} />
          </div>
        </div>
      )}
    </>
  );
}
export default EMICalc;
