import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ApplyInstantComponent from "../Components/applyInstantComponent";
import ProfileBank from "../Components/profileBank";
import axios from "axios";
import { createOrder, digiLockerResponse } from "../api/cashfree";
import { getLocalStorage } from "../Common/cookie";
import { getCustomer } from "../api/customer";
import Swal from "sweetalert2";
import { updatePLStatus } from "../api/loan";
function ApplyNowInstant6(props) {
  const { socket, logindata, setLogindata } = props;
  // const query = new URLSearchParams(useLocation().search);
  // const id = query.get("id");
  // const verify = query.get("verify");
  const navigate = useNavigate();
  const location = useLocation();
  const queryString = location.search || location.pathname.split('?')[1];

  // Convert query string to a usable format
  const params = new URLSearchParams(queryString.replace(/\?/g, '&')); // Normalize query structure
  const id = params.get('id'); // Fetch `id`
  const verify = params.get("verify")
  const verificationId = params.get('verification_id'); 

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getCust();
  }, []);

  const getCust = async () => {
    const checkCookie = getLocalStorage("rv-user");
    if (checkCookie) {
      const response = await getCustomer({
        customer_id: checkCookie.id,
        token: checkCookie.accessToken,
      });
      if (response.status === 200) {
        if (!verify) {
          const payload = JSON.parse(response.data.aadhar_data);
          payload.loan_id = id
          const res = await digiLockerResponse({
            data: payload,
            // data: { ...payload, customer_id: checkCookie.id, loan_id: id },
            token: checkCookie.accessToken,
          });
          if (res.status === 200) {
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went erong in your e-KYC.",
            });
          }
        }
      }
    }
  };
  async function handleBankDetails(status) {
    if (status === "success") {
      navigate(`/apply-now/Instant/bank-statement?id=${id}`);
    }
  }

  return (
    <>
      <ApplyInstantComponent>
        <h5>
          <center>Bank Verification</center>
        </h5>
        <div class="contact-form pb-2">
          <div class="form-group mt-30">
            <ProfileBank
              msg="Proceed"
              onSubmit={handleBankDetails}
              logindata={logindata}
              socket={socket}
              loanId={id}
            />
          </div>
        </div>
      </ApplyInstantComponent>
    </>
  );
}
export default ApplyNowInstant6;
