import { useEffect, useState } from "react";
import logo from "../Assets/img/logoLight.png";
import instant from "../Assets/img/Loan/instant.png";
import interest from "../Assets/img/LoanIcon/interest.png";
import approveGreen from "../Assets/img/LoanIcon/approveGreen.png";
import online2 from "../Assets/img/LoanIcon/online2.png";
import { RWebShare } from "react-web-share";
import { Link, useNavigate } from "react-router-dom";

function ApplyNowInstantBanner(props) {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handleBasicsDetails() {
    navigate("/apply-now/Instant/Basics");
  }

  return (
    <div
      class="container-fluid"
      style={{ background: "#e5eaee", height: "100%", minHeight: "100vh" }}
    >
      <div class="container">
        <div className="row pt-2 d-flex justify-content-between">
          <div>
            <Link to="/">
              {" "}
              <img
                src={logo}
                alt="logo"
                height={95}
                width={95}
                style={{ mixBlendMode: "multiply" }}
              />
            </Link>
          </div>
          {/* <div className="d-flex align-items-center"> */}
          {/* <RWebShare
              data={{
                //   text: "Like humans, flamingos make friends for life",
                url: "https://rvcapitalfinance.com/apply-now/Instant",
                title: "RV Capital Finance",
              }}
              onClick={() => console.log("shared successfully!")}
            >
              <button
                style={{ border: "2px solid #002d5b", borderRadius: "8px" }}
              >
                🔗
              </button>
            </RWebShare> */}
          {/* </div> */}
          {/* <div className="instant-company"> RV Capital Finance</div> */}
        </div>
        <div className="row mt-3">
          {/* <div className="col-lg-3"></div> */}
          <div className="col-lg-6 col-12 mt-45">
            <h4>GET A</h4>
            <h2>
              <b>PERSONAL LOAN</b>
            </h2>
            <h4>
              IN <b>FEW CLICKS</b>
            </h4>
            <div className="d-flex justify-content-between mt-45 mr-3">
              <div className="p-2">
                <center>
                  <img src={interest} alt="interest" height={70} />
                  <h6 className="mt-2">LOW RATE OF INTEREST</h6>
                </center>
              </div>
              <div className="p-2">
                <center>
                  <img src={approveGreen} alt="instant" height={70} />
                  <h6 className="mt-2">INSTANT APPROVAL</h6>
                </center>
              </div>
              <div className="p-2">
                <center>
                  <img src={online2} alt="online" height={70} />
                  <h6 className="mt-2">COMPLETELY ONLINE</h6>
                </center>
              </div>
            </div>
            <div class="d-none d-lg-block">
              <button
                className="default-btn mt-45"
                onClick={handleBasicsDetails}
              >
                Apply Now
              </button>
            </div>
          </div>
          <div
            className="col-lg-6 col-12" // position-fixed
            style={{ right: "15px" }}
          >
            <div className="d-flex justify-content-center">
              <img
                src={instant}
                alt="instant loan"
                style={{ position: "sticky" }}
              />
            </div>
            <div className="instant-summary">
              <div className="row">
                <div className="col">
                  <ul>
                    <li>Attractive Interest Rate</li>
                    <li>No Hidden Charges</li>
                  </ul>
                </div>
                <div className="col">
                  <ul>
                    <li>Quick Approvals</li>
                    <li>Zero Pre-payment Charges</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="d-block d-lg-none">
              <center>
                <button
                  className="default-btn mt-45 mb-30"
                  onClick={handleBasicsDetails}
                >
                  Apply Now
                </button>
              </center>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ApplyNowInstantBanner;
