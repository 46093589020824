import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ApplyInstantComponent from "../Components/applyInstantComponent";
import ReviewLoan from "../Components/reviewLoan";
import pdf from "../Assets/img/pdf.png";
import { getLocalStorage } from "../Common/cookie";
import { getLoan } from "../api/loan";
import { validateText } from "../Common/validations";
import { uploadBankStatement } from "../api/customerAuth";

function ApplyNowInstantStatement(props) {
  const { socket, logindata, setLogindata } = props;
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [statementDB, setStatementDB] = useState("");
  const [selectedStatementFile, setSelectedStatementFile] = useState({
    preview: "",
    raw: "",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-user");
      if (checkCookie) {
        const response = await getLoan({
          token: getLocalStorage("rv-user")?.accessToken,
          loan_id: id,
        });
        if (response.status === 200) {
          setStatementDB(response.data.statement);
        }
      }
    };
    asyncFn();
  }, [logindata, id]);

  const handleStatementUpload = (event) => {
    const file = event.target.files[0];
    setSelectedStatementFile({
      preview: URL.createObjectURL(file),
      raw: file,
    });
  };

  function validateStatementFile() {
    setError(
      validateText(
        selectedStatementFile?.preview
          ? selectedStatementFile?.preview
          : statementDB
      ).error
    );
  }

  function validateForm() {
    validateStatementFile();
    let valid = validateText(
      selectedStatementFile?.preview
        ? selectedStatementFile?.preview
        : statementDB
    ).valid;
    return valid;
  }

  async function saveData(e) {
    e.preventDefault();
    if (validateForm()) {
      if (selectedStatementFile.raw) {
        const formData = new FormData();
        formData.append("loan_id", id);
        formData.append(
          "statement",
          selectedStatementFile.raw ? selectedStatementFile.raw : null
        );
        const response = await uploadBankStatement({
          token: getLocalStorage("rv-user")?.accessToken,
          data: formData,
        });
        if (response.status === 200) {
          navigate(`/apply-now/Instant/review?id=${id}`);
        }
      } else {
        navigate(`/apply-now/Instant/review?id=${id}`);
      }
    }
  }

  async function skipData(){
    navigate(`/apply-now/Instant/review?id=${id}`);
  }

  return (
    <>
      <ApplyInstantComponent>
        <h5>
          <center>Upload Bank Statement</center>
        </h5>
        <div class="contact-form pb-2">
          <div class="form-group mt-30">
            Upload last 3 months statement in PDF format only
            <div
              style={{
                width: "200px",
                height: "50px",
                position: "relative",
                background: "#f9f9f9",
                overflow: "hidden",
                borderRadius: "8px",
                border: "1px solid #ccc",
              }}
            >
              {(() => {
                if (selectedStatementFile?.raw?.name) {
                  const f_name =
                    selectedStatementFile.raw.name.length > 20
                      ? selectedStatementFile.raw.name.substring(0, 20) + ".."
                      : selectedStatementFile.raw.name;
                  return (
                    <div className="d-flex p-1">
                      <div>
                        <img src={pdf} alt="pdf" height="40" width="40" />
                      </div>
                      <div
                        className="d-flex align-items-center"
                        style={{ fontSize: "12px" }}
                      >
                        {f_name}
                      </div>
                    </div>
                  );
                  // return <embed src={selectedStatementFile.preview} style={{ width: "150px", height: "150px" }} type="application/pdf" />
                } else if (statementDB) {
                  const parts = statementDB.path.split("/");
                  const filename = parts[parts.length - 1];
                  const parts1 = filename.split("-");
                  const filename1 = parts1[parts1.length - 1];
                  const f_name =
                    filename1.length > 20
                      ? filename1.substring(0, 20) + ".."
                      : filename1;
                  return (
                    <div className="d-flex p-1">
                      <div>
                        <img src={pdf} alt="pdf" height="40" width="40" />
                      </div>
                      <div
                        className="d-flex align-items-center"
                        style={{ fontSize: "12px" }}
                      >
                        {f_name}
                      </div>
                    </div>
                  );
                  // return <embed src={`${process.env.REACT_APP_API}${statementDB.path}`} style={{ width: "150px", height: "150px" }} type="application/pdf" />
                } else {
                  return (
                    <center style={{ marginTop: "10px" }}>
                      Upload Statement
                    </center>
                  );
                }
              })()}
              <input
                type="file"
                onChange={handleStatementUpload}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  opacity: 0,
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                }}
                accept="application/pdf"
              />
            </div>
            {error && <div class="help-block with-errors">{error}</div>}
          </div>
          <button
            type="submit"
            onClick={saveData}
            class="default-btn disabled"
            style={{ pointerEvents: "all", cursor: "pointer" }}
          >
            Proceed <span></span>
          </button>
          <button
            type="submit"
            onClick={skipData}
            class="default-btn disabled"
            style={{ pointerEvents: "all", cursor: "pointer", marginLeft: "30px" }}
          >
            Skip <span></span>
          </button>
        </div>
      </ApplyInstantComponent>
    </>
  );
}
export default ApplyNowInstantStatement;
