import axios from "axios";


export const verifyPAN = (obj) => {
  return axios
    .post("/cashfree-verification/pan", obj.data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};
export const createOrder = (obj) => {
  return axios
    .post("/cashfree", obj.data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};
export const paymentStatus = (obj) => {
  return axios
    .get("/cashfree/" + obj.order_id)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};

export const createDigiLockerLink = (obj) => {
  return axios
    .post("/cashfree-verification/create-digi-locker-link", obj.data, {
      headers: { Authorization: `Bearer ${obj.token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};
export const digiLockerResponse = (obj) => {
  return axios
    .post("/cashfree-verification/digi-locker-status", obj.data, {
      headers: { Authorization: `Bearer ${obj.token}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error", err.response.data.message);
      return err.response.data;
    });
};