import { validateContact, validateOTP } from "../Common/validations";
import { useEffect, useState } from "react";
import { customerLogin, customerOtp } from "../api/customerAuth";
import { getCustomer } from "../api/customer";
import { setLocalStorage } from "../Common/cookie";
import Swal from "sweetalert2";

function LoginForm(props) {
  const { setShow, onSubmit, message, logindata, setLogindata } = props;

  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [seconds, setSeconds] = useState(null);

  const [code, setcode] = useState(null);
  const [otpdata, setOtpdata] = useState(null);
  const [clogindata, setClogindata] = useState(null);

  function onChangeData(e) {
    if (e.target.name === "phone" && isNaN(e.target.value)) {
      return false;
    }
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  }

  function validatePhoneNo(str) {
    setErrors((errors) => ({
      ...errors,
      phone: validateContact(str).error,
    }));
  }

  function validateForm() {
    validatePhoneNo(data.phone);
    let valid = validateContact(data.phone).valid;
    return valid;
  }

  const sendOTP = async (e) => {
    e.preventDefault();
    setErrors((errors) => ({
      ...errors,
      otp: "",
    }));
    if (validateForm()) {
      const response = await customerLogin({
        data: {
          phone: data.phone,
        },
      });
      if (response.status === 200) {
        Swal.fire({
          // title: "Good job!",
          // text: `We have sent an OTP to your mobile number ******${data.phone.slice(-4)}.`,
          html: `We have sent an <b>OTP</b> to your mobile number <b>******${data.phone.slice(
            -4
          )}</>.`,
          icon: "success",
          confirmButtonText: `Okay`,
          confirmButtonColor: "#002d5b",
        });
        setOtpdata(response.data);
        if (response.data.phone_verified === 1) {
          setSeconds(120);
        } else {
          setSeconds(600);
        }
      } else {
        setErrors({
          ...errors,
          phone: response.message,
        });
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);
    if (seconds === 0) {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [seconds]);

  const handleChange = (e) => {
    if (isNaN(e.target.value)) return false;
    setcode(e.target.value);
  };

  function validateCode(str) {
    setErrors((errors) => ({
      ...errors,
      otp: validateOTP(str).error,
    }));
  }

  function validateOTPForm() {
    validateCode(code);
    let valid = validateOTP(code).valid;
    return valid;
  }

  const loginform = async (e) => {
    e.preventDefault();
    if (validateOTPForm()) {
      const response = await customerOtp({
        data: {
          id: otpdata.id.toString(),
          otp: code,
          type: otpdata.phone_verified === 0 ? "registration" : "login",
        },
      });
      if (response.status === 200) {
        setClogindata(response.data);
        setShow(false);
        setLogindata(true);
        setLocalStorage("rv-user", {
          id: response.data.id,
          accessToken: response.data.accessToken,
        });
        const response1 = await getCustomer({
          customer_id: response.data.id,
          token: response.data.accessToken,
        });
        onSubmit("success");
      } else {
        setErrors({
          ...errors,
          otp: response?.message || "OTP is incorrect",
        });
        setcode(null);
      }
    }
  };

  return (
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label className="label-login">Mobile No.</label>
          {/* style={{ maxWidth: "260px" }} */}
          <input
            type="text"
            name="phone"
            class="form-control"
            value={data.phone}
            onChange={(e) => onChangeData(e)}
          />
          {errors.phone && (
            <div class="help-block with-errors">{errors.phone}</div>
          )}
        </div>
      </div>
      {otpdata && (
        <div class="col-12">
          <div class="form-group">
            <div className="d-flex justify-content-between">
              <label className="label-login">OTP</label>
              <label className="label-login" style={{ fontWeight: 400 }}>
                {seconds === 0
                  ? "OTP Expired"
                  : seconds >= 60
                  ? `${Math.floor(seconds / 60)}:${(seconds % 60)
                      .toString()
                      .padStart(2, "0")}`
                  : `0:${seconds}`}
              </label>
            </div>
            {/* style={{ maxWidth: "260px" }} */}
            <input
              type="text"
              class="form-control"
              name="otp"
              value={code}
              onChange={(e) => handleChange(e)}
            />
            {errors.otp && (
              <div class="help-block with-errors">{errors.otp}</div>
            )}
          </div>
        </div>
      )}
      <div class="col-12">
        {otpdata ? (
          <button onClick={loginform} className="default-btn btn-sm mt-3">
            {message}
          </button>
        ) : (
          <button onClick={sendOTP} className="default-btn btn-sm mt-3">
            Send OTP
          </button>
        )}
      </div>
      {otpdata && (
        <div class="col-12 mt-3 text-center">
          Haven't received OTP?{" "}
          <button onClick={sendOTP} className="resend-btn">
            Resend OTP
          </button>
        </div>
      )}
    </div>
  );
}

export default LoginForm;
