import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApplyInstantComponent from "../Components/applyInstantComponent";
import ProfileProfessional from "../Components/profileProfessional";
import salaried from "../Assets/img/Loan/salaried.png";
import nonSalaried from "../Assets/img/Loan/nonSalaried.png";

function ApplyNowInstantOccCat(props) {
  const { socket, logindata, setLogindata } = props;
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const changeType = (type) => {
    navigate(`/apply-now/Instant/professional?type=${type}`);
  }

  return (
    <>
      <ApplyInstantComponent>
      <h5><center>Employement Category</center></h5>
        <div class="contact-form pb-2">
          <div className="row justify-content-center mt-30">
            <div className="col-lg-3 col-md-4 col-6">
              <div className="single-about-box p-2 py-3" style={{cursor:"pointer"}} onClick={(e)=>changeType("Salaried")}>
                <img src={salaried} alt="" height="50" width="50" />
                <p>Salaried</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-6">
              <div className="single-about-box p-2 py-3" style={{cursor:"pointer"}} onClieck={(e)=>changeType("Non Salaried")}>
              <img src={nonSalaried} alt="" height="50" width="50" />
                <p>Non Salaried</p>
              </div>
            </div>
          </div>
        </div>
      </ApplyInstantComponent>
    </>
  );
}
export default ApplyNowInstantOccCat;
