import { useEffect, useState } from "react";
import logo from "../Assets/img/logo.png";
import applyNow from "../Assets/img/Banner/applyNow.png";
import creditCard from "../Assets/img/Banner/creditCard.png";
import whatsapp from "../Assets/img/Banner/whatsapp.png";
import cibil from "../Assets/img/Banner/scoreGIF.gif";

import { toast } from "react-toastify";
import { validateEmail } from "../Common/validations";
import { Link } from "react-router-dom";

import { addSubscriber } from "../api/subscribe";

function Footer() {
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  function onChangeData(e) {
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  }

  function validateEmailAddr(str) {
    setErrors((errors) => ({
      ...errors,
      email: validateEmail(str).error,
    }));
  }

  function validateForm() {
    validateEmailAddr(data.email);
    let valid = validateEmail(data.email).valid;
    return valid;
  }

  const addsubscribe = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const response = await addSubscriber({
        data: {
          email: data.email,
        },
      });
      if (response.status === 200) {
        toast.success("Your email subscribed successfully.");
        setData({
          ...data,
          email: "",
        });
      } else {
        setErrors({
          ...errors,
          email: response?.message,
        });
      }
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <footer class="footer-area">
        <div class="container">
          <div class="subscribe-area">
            <div class="row align-items-center">
              <div class="col-lg-5 col-md-12">
                <div class="subscribe-content">
                  <h2>Join Our Newsletter</h2>
                  <p>
                    Subscribe to our newsletter for the latest updates and
                    insights on loan finance solutions.
                  </p>
                </div>
              </div>

              <div class="col-lg-7 col-md-12">
                <div class="subscribe-form">
                  <form
                    class="newsletter-form"
                    data-toggle="validator"
                    novalidate="true"
                  >
                    <input
                      type="email"
                      class="input-newsletter"
                      placeholder="Enter your email address"
                      name="email"
                      value={data.email}
                      onChange={(e) => onChangeData(e)}
                    />

                    <button onClick={addsubscribe} class="disabled">
                      {/* style="pointer-events: all; cursor: pointer;" */}
                      Subscribe <i class="flaticon-right-chevron"></i>
                    </button>
                    {errors.email && (
                      <div class="help-block with-errors text-white">
                        {errors.email}
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4 col-12">
              <div class="single-footer-widget">
                <div class="logo">
                  <Link to="/">
                    <img src={logo} alt="image" height="100" width="100" />
                  </Link>
                  <p className="text-justify">
                    RV Capital offers tailored loan solutions for RV
                    enthusiasts, providing competitive rates and personalized
                    service. Hit the road with confidence through our
                    hassle-free application process.
                  </p>
                </div>

                <ul class="social">
                  <li>
                    {" "}
                    <a
                      href="https://x.com/rvcapital1/"
                      target="_blank"
                    >
                      <i class="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a
                      href="https://www.linkedin.com/in/rv-capital-367818309?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
                      target="_blank"
                    >
                      <i class="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a
                      href="https://www.youtube.com/@rvcapital-fp3ol"
                      target="_blank"
                    >
                      <i class="fab fa-youtube"></i>
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a
                      href="https://www.facebook.com/people/RV-Capital/pfbid02qAQT4mo87TExNZpPUcMuTZpYhgzMJvCC7rugqtyydLtpeVkHZkaeDQcdoeAnsmJ2l/"
                      target="_blank"
                    >
                      <i class="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a
                      href="https://www.instagram.com/rvcapital_/"
                      target="_blank"
                    >
                      <i class="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-lg-4 col-12">
              <div class="single-footer-widget">
                <h3>Quick Links</h3>

                <ul class="footer-quick-links">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="/policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms">Terms &amp; Conditions</Link>
                  </li>
                  <li>
                    <Link to="/disclaimer">Disclaimer</Link>
                  </li>
                  <li>
                    <Link to="/refund">Refund &amp; Cancellation Policy</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-lg-4 col-12">
              <div class="single-footer-widget">
                <h3>Contact Info</h3>

                <ul class="footer-contact-info">
                  <li>
                    <span>Location:</span> RV Capital Finance, Near Purushottam
                    School, Dhaniv baug, Harvate pada nalasopara, East Virar,
                    401208.
                  </li>
                  <li>
                    <span>Email:</span>{" "}
                    <Link to="#">{process.env.REACT_APP_EMAIL}</Link>
                  </li>
                  {/* <li><span>Phone:</span> <Link to="#">{process.env.REACT_APP_CONTACT}</Link></li> */}
                  <li>
                    <Link
                      to="https://maps.app.goo.gl/PzapXAuA3PSdeRgS9"
                      target="_blank"
                    >
                      View Location on GoogleMap
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="copyright-area">
            <div class="row align-items-center justify-content-center">
              {/* <ul>
                <li>
                  <Link to="/policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms">Terms &amp; Conditions</Link>
                </li>
                <li>
                  <Link to="/refund">Refund &amp; Cancellation Policy</Link>
                </li>
              </ul> */}
              <p>
                <i class="far fa-copyright"></i>{" "}
                <script>document.write(new Date().getFullYear())</script>
                Prophesier Digital Technology Pvt Ltd. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
      <div class="go-top" onClick={scrollToTop}>
        <i class="fas fa-chevron-up"></i>
        <i class="fas fa-chevron-up"></i>
      </div>
      <div class="quick-apply quick-ql">
        <Link to="/apply-now" class="d-block mb-2 position-relative">
          <img src={applyNow} alt="Quick Apply logo" width="40" height="40" />
          <span>Quick Apply</span>
        </Link>
        <Link to="/apply-cc" class="d-block mb-2 position-relative">
          <img src={creditCard} alt="Credit card logo" width="40" height="40" />
          <span>Apply for Credit Card</span>
        </Link>
        {/* <Link to="/" class="d-block mb-2 position-relative">
          <img src={cibil} alt="CIBIL Score logo" width="40" height="40" />
          <span>Free CIBIL</span>
        </Link> */}
        {/* <Link to={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_CONTACT_LINK}&text=RV Capitals`} target="_blank" class="d-block position-relative">
                    <img src={whatsapp} alt="Whatsapp logo" width="40" height="40" />
                    <span>Connect on WhatsApp</span>
                </Link> */}
      </div>
    </>
  );
}
export default Footer;
