import { Link } from "react-router-dom";

function LoanFromBankComponent() {
  const data = [
    {
      name: "Personal Loan",
      desc: "Get the financial freedom you need with a personal loan from leading banks – quick approval, flexible repayment, and no collateral required!",
      link: "/personal-loan-from-bank",
      upto: "5L",
    },
    {
      name: "Business Loan",
      desc: "Fuel Your Growth with Tailored Business Loans – Empowering Your Vision, Expanding Your Horizon!",
      link: "/business-loan-from-bank",
      upto: "50L",
    },
  ];
  return (
    <>
      <div className="section-60">
        <div className="container">
          <div class="about-inner-area mt-0 mb-30">
            <div class="row justify-content-center m-0">
              {data.map((d) => {
                return (
                  <div class="col-md-6 col-12">
                    <div
                      class="about-text-box h-100 pb-0"
                      style={{ border: "1px solid #f8f8f8", backgroundColor: "#f8f8f8" }}
                    >
                      <Link to={d.link}>
                        <div
                          className="d-flex flex-column justify-content-between"
                          style={{ height: "calc(100% - 30px)" }}
                        >
                          <div>
                            <h3>{d.name}</h3>
                            <p className="text-justify">{d.desc}</p>
                          </div>
                          <div>
                            <div className="d-flex justify-content-between">
                              <div>
                                <p className="mb-0 mt-1">
                                  <i>
                                    <b>Get up to</b>
                                  </i>
                                </p>
                                <p style={{ lineHeight: "15px" }}>
                                  <b>₹ {d.upto}</b>
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <i
                                  class="fas fa-arrow-circle-right"
                                  style={{
                                    fontSize: "30px",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default LoanFromBankComponent;
