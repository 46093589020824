import BreadCrumbs from "../Components/breadcrumbs";
import img1 from "../Assets/img/Loan/home1.png";
import img2 from "../Assets/img/Loan/home2.png";
import ApplyNowForm from "../Components/applyNowForm";
import { useEffect } from "react";
import LoanOptions from "../Components/loanOptions";

function ApplyNowHome(props) {
  const {socket, logindata, setLogindata} = props
  const paths = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Apply Now",
    },
    {
      name: "Home Loan",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BreadCrumbs paths={paths} />
      <div class="container">
        <div class="section-60">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 d-none d-lg-block">
              <div class="about-image">
                <img src={img1} alt="image" />
                <img src={img2} alt="image" />
              </div>
            </div>

            <div class="col-lg-6 col-md-12">
              <div className="py-3">
                <div class="section-title text-left">Get Home Loan</div>
                <ApplyNowForm type="Home" applyFor="Loan" logindata={logindata} setLogindata={setLogindata} type_name="Home Loan" />
              </div>
            </div>
          </div>
        </div>
        <LoanOptions applyLoan type="Home" />
      </div>
    </>
  );
}
export default ApplyNowHome;
