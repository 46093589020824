import { useEffect, useState } from "react";
import ProfilePersonal from "../Components/profilePersonal";
import ProfileIdentity from "../Components/profileIdentity";
import ProfileAddress from "../Components/profileAddress";
import ProfileProfessional from "../Components/profileProfessional";
import { getCustomer } from "../api/customer";
import { useNavigate } from "react-router-dom";
import { getLocalStorage } from "../Common/cookie";
import { toast } from "react-toastify";
import ProfileBank from "../Components/profileBank";
import { getLocation } from "../api/location";

function Profile({ logindata, setLogindata }) {
  const navigate = useNavigate();
  const [data, setData] = useState({});

  useEffect(() => {
    const asyncFn = async () => {
      const checkCookie = getLocalStorage("rv-user");
      if (checkCookie) {
        await getCust(checkCookie.id, checkCookie.accessToken);
      }
    };
    asyncFn();
  }, [logindata]);

  const getCust = async (id, token) => {
    const response = await getCustomer({
      customer_id: id,
      token: token,
    });
    if (response.status === 200) {
      setData(response.data);
      if (response.data.pincode) {
        await setLocation(response.data.pincode);
      }
    }
  };

  const setLocation = async (pincode) => {
    if (pincode && pincode.length === 6) {
      const location = await getLocation({
        pincode: pincode,
      });
      if (location[0]?.Status === "Success") {
        const lData = location[0].PostOffice[0];
        setData((data) => ({
          ...data,
          city: lData.District,
          state: lData.State,
          country: lData.Country,
        }));
      }
    }
  };

  return (
    <div className="container">
      <div className="section-60">
        <h5>Personal Details:</h5>
        <div className="card p-3">
          <div className="row">
            <div className="col-lg-8 col-12 order-lg-1 order-2">
              <table>
                <tr>
                  <td>Name</td>
                  <td>{data.name}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{data.email}</td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>{data.phone}</td>
                </tr>
                <tr>
                  <td>DOB</td>
                  <td>{new Date(data.dob).toLocaleDateString()}</td>
                </tr>
                <tr>
                  <td>Gender</td>
                  <td>
                    {data.gender?.charAt(0).toUpperCase() +
                      data.gender?.slice(1).toLowerCase()}
                  </td>
                </tr>
                <tr>
                  <td>Marital Status</td>
                  <td>
                    {data.marital_status?.charAt(0).toUpperCase() +
                      data.marital_status?.slice(1).toLowerCase()}
                  </td>
                </tr>
                <tr>
                  <td>Aadhar No.</td>
                  <td>{data.aadhar_no}</td>
                </tr>
                <tr>
                  <td>PAN No.</td>
                  <td>{data.pan_no}</td>
                </tr>
              </table>
            </div>
            <div className="col-lg-4 col-12 order-lg-2 order-1 d-flex justify-content-center">
              {data.profile && (
                <img
                  src={process.env.REACT_APP_API + "/uploads/Customer/" + data.profile}
                  style={{ width: "150px", height: "200px" }}
                />
              )}
            </div>
          </div>
        </div>
        {data?.address?.[0] && (
          <>
            <h5 className="mt-30">Address Details:</h5>
            <div className="card p-3">
              <div className="row">
                <div className="col-lg-8 col-12">
                  <table>
                    <tr>
                      <td>AddressLine1</td>
                      <td>{data.address[0].addressline1}</td>
                    </tr>
                    <tr>
                      <td>AddressLine2</td>
                      <td>{data.address[0].addressline1}</td>
                    </tr>
                    <tr>
                      <td>Area</td>
                      <td>{data.address[0].area}</td>
                    </tr>
                    <tr>
                      <td>City</td>
                      <td>{data.address[0].city}</td>
                    </tr>
                    <tr>
                      <td>State</td>
                      <td>{data.address[0].state}</td>
                    </tr>
                    <tr>
                      <td>Country</td>
                      <td>{data.address[0].country}</td>
                    </tr>
                    <tr>
                      <td>PinCode</td>
                      <td>{data.address[0].pincode}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
        {data?.bank?.[0] && (
          <>
            <h5 className="mt-30">Bank Details:</h5>
            <div className="card p-3">
              <div className="row">
                <div className="col-lg-8 col-12">
                  <table>
                    <tr>
                      <td>Account No.</td>
                      <td>{data.bank[0].ac_no}</td>
                    </tr>
                    <tr>
                      <td>IFSC Code</td>
                      <td>{data.bank[0].ifsc_code}</td>
                    </tr>
                    <tr>
                      <td>Bank Name</td>
                      <td>{data.bank[0].bank_name}</td>
                    </tr>
                    <tr>
                      <td>City</td>
                      <td>{data.bank[0].branch_name}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
export default Profile;
